import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

const DateSelection = ({ onDateRangeChange, api }) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (onDateRangeChange) {
      onDateRangeChange(dates); // Pass dates to parent component
    }
    if(dates?.[0] && dates?.[1]){
      dispatch(api({
        startDate: moment(dates?.[0]).format("YYYY/MM/DD"),
        endDate: moment(dates?.[1]).format("YYYY/MM/DD")
    }))
    }
  };

  return (
    <>
      <label>Date Range</label>
      <br />
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        placeholderText="Select a date range"
        dateFormat="yyyy-MM-dd"
        popperPlacement="bottom-end"
        customInput={
          <button className="custom-date-input">
            {startDate && endDate
              ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
              : "Select a date range"}
          </button>
        } 
      />
    </>
  );
};

export default DateSelection;
