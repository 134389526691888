import React, { useEffect, useRef, useState } from "react";
import Footer from "../Sub/Footer";
import sumBy from "lodash/sumBy";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Sub/Header";
import { Button, Col, Row } from "react-bootstrap";
import {
  applyCartOffer,
  checkAddressArea,
  emptyCartlist,
  getCartlist,
  getOffers,
  updateCart,
} from "../../reducers/commonReducer";
import Spinner from "react-bootstrap/Spinner";
import "../../table.css";
import { Helmet } from "react-helmet";
import { getUserAddress, product, shop, baseUrl, home } from "../../const";
import OrderImgPopUp from "./OrderImgPopUp";
import Offers from "./user/Offers";

const Checkout = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasTracked, setHasTracked] = useState(false);
  const [coupon, setCoupon] = useState();
  const [discount, setDiscount] = useState(0);
  const [couponStatus, setCouponStatus] = useState(false);

  //api's
  useEffect(() => {
    localStorage.setItem("cartoffer", false)
    localStorage.removeItem("couponapplyName")
    setLoading(true);
    dispatch(getCartlist());
    dispatch(getOffers());
    setLoading(false);
    const intervalId = setInterval(() => {
      dispatch(getCartlist());
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  const { getCartlistData, offersData } = useSelector(
    (state) => state.commonReducer
  );

  //facebook script
  useEffect(() => {
    if (
      hasTracked ||
      typeof window.fbq !== "function" ||
      !getCartlistData?.list ||
      !Array.isArray(getCartlistData.list) ||
      getCartlistData.list.length === 0
    ) {
      return;
    }
    const contents = getCartlistData.list.map((item) => ({
      id: item?.product?.sku || "Unknown ID",
      quantity: item?.quantity || 1,
      price: item?.product?.sale || 0,
      content_category: item?.product?.categories?.name || "Uncategorized",
      content_name: item?.product?.name || "Unknown Product",
    }));
    window.fbq("track", "AddToCart", {
      contents,
      content_type: "product",
      currency: "INR",
    });
    setHasTracked(true);
  }, [getCartlistData, hasTracked]);

  //update quantity
  const updateQuantity = async (quantity, id, itemRemove) => {
    if (itemRemove || quantity >= 1) {
      const apiResp = await dispatch(
        updateCart({ quantity, id, delete: itemRemove })
      );
      if (apiResp?.payload?.status) {
        dispatch(getCartlist());
        dispatch(getOffers());
      }
    }
    if (
      localStorage.getItem("couponName") !== "undefined" &&
      localStorage.getItem("couponName") !== "null" &&
      localStorage.getItem("couponName")?.length > 0
    ) {
      const apiResp = await dispatch(
        applyCartOffer({
          qty: quantity,
          couponName: localStorage.getItem("couponName"),
        })
      );
      if (apiResp) {
        localStorage.setItem("couponName", apiResp.payload.offer?.id);
        localStorage.setItem("couponapplyName", apiResp.payload.offer?.name);
        localStorage.setItem("couponapplyAmount", apiResp.payload.disc);
        updateCouponAmountStatus(
          apiResp.payload.offer?.rate,
          true,
          apiResp.payload.disc
        );
        localStorage.removeItem("code");
        localStorage.setItem("cartoffer", true);
        localStorage.setItem("couponoffer", false);
        setDiscount(+apiResp.payload.disc);
      }
    }
  };
  useEffect(() => {
    if (getCartlistData?.list?.length === 0) {
      const timeout = setTimeout(() => {
        navigate(home);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [getCartlistData?.list, navigate]);

  let totalAmount = 0;
  const isAllCustomize = getCartlistData?.list?.every((item) => {
    if (item?.product?.productType === "customize") {
      return item?.mergeimg !== "";
    }
    return true;
  });
  //coupon amount and Qty
  const updateCouponAmountStatus = (amount, status, discountAmount) => {
    setCoupon(amount);
    setCouponStatus(status);
    setDiscount(+discountAmount);
    localStorage.setItem("cartoffer", status);
  };

  //total cart amount for offers
  const calculateCartSummary = (cartData) => {
    const cartList = cartData?.list || [];
    const totalCartAmountValue = sumBy(
      cartList,
      (item) => item.quantity * item.price
    );
    const cartItems = sumBy(cartList, "quantity");
    return { totalCartAmountValue, cartItems };
  };
  const { totalCartAmountValue, cartItems } =
    calculateCartSummary(getCartlistData);
  // const discount = (coupon / 100) * totalCartAmountValue;
  // const discount = +(localStorage.getItem("couponapplyAmount"));

  return (
    <>
      <Helmet>
        <title>Ranbazaar - Checkout</title>
        <meta name="description" content="Ranbazaar Private Limited" />
        <link rel="canonical" href={`${baseUrl}checkout`} />
        <meta property="og:title" content="Ranbazaar - Checkout" />
        <meta property="og:site_name" content="Ranbazaar" />
        <meta property="og:url" content={`${baseUrl}checkout`} />
        <meta property="og:description" content="Ranbazaar Private Limited" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/ranbazar.png" />
      </Helmet>
      <Header />
      <div className="container pb-5 mt-4 order">
        <Row>
          <Col md={6} sm={6} xs={6}>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <b>
                <span className="your-cart">Your Cart</span>
              </b>
            </h4>
          </Col>
          <Col md={6} sm={6} xs={6}>
            <Button
              variant="danger"
              className="f-right"
              onClick={async () => {
                const apiResp = await dispatch(emptyCartlist());
                if (apiResp?.payload?.status) {
                  dispatch(getCartlist());
                  navigate(shop);
                }
              }}
            >
              Cart Clear
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Row className="justify-content-center">
            <Spinner animation="border" />
          </Row>
        ) : (
          <>
            <Row className="no-more-tables">
              <Col md={12}>
                {getCartlistData?.list?.length ? (
                  <table striped className="overflow-hidden">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Qty</th>
                        <th></th>
                        <th>Price</th>
                        <th>SubTotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCartlistData?.list?.map((item, index) => {
                        const subTotal = item?.quantity * item?.price;
                        totalAmount += subTotal;
                        let productImg =
                          item?.product?.productType === "customize"
                            ? item?.mergeimg
                            : item?.product?.images?.[0];
                        return (
                          <tr
                            key={index}
                            className={width.current < 500 ? "mt-2" : null}
                          >
                            <td data-title="Sr No.">{index + 1}</td>
                            <td data-title="Product Name" className="f-13">
                              <Link to={`${product}/${item?.product?.slug}`}>
                                {item?.product?.name}
                              </Link>
                            </td>
                            <td data-title="Image">
                              {productImg ? (
                                <OrderImgPopUp
                                  title={"Check You Product"}
                                  image={productImg}
                                  alt={item?.product?.name}
                                />
                              ) : (
                                <div
                                  className="spinner-border"
                                  role="status"
                                ></div>
                              )}
                            </td>
                            <td data-title="Qty">
                              <div className="numbers text-center d-flex">
                                <span
                                  className="minus bg-danger"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity - 1, item?.id)
                                  }
                                >
                                  -
                                </span>
                                <input
                                  className="w-70"
                                  type="number"
                                  min={1}
                                  disabled
                                  value={item?.quantity}
                                />
                                <span
                                  className="plus bg-success"
                                  onClick={async () =>
                                    updateQuantity(item?.quantity + 1, item?.id)
                                  }
                                >
                                  +
                                </span>
                              </div>
                            </td>
                            <td data-title="Delete">
                              <Button
                                variant="danger"
                                className="px-2 py-1  "
                                onClick={async () =>
                                  updateQuantity(0, item?.id, true)
                                }
                              >
                                <i className="bi bi-trash3"></i>
                              </Button>
                            </td>
                            <td data-title="Price" className="text-black">
                              <span className="discount-price text-danger">
                                Rs.{item?.product?.mrp}
                              </span>{" "}
                              Rs.{item?.price}
                            </td>
                            <td data-title="SubTotal">
                              <b>{item?.quantity * item?.price}/-</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {width.current > 500 ? (
                      <tfoot>
                        <tr className="totalsum">
                          <th colSpan={6}>Total</th>
                          <th>{totalAmount}/-</th>
                        </tr>
                      </tfoot>
                    ) : (
                      <Row className="mt-1">
                        <Col xs={9} sm={9}>
                          <h5 className="text-black">
                            <b>Total</b>
                          </h5>
                        </Col>
                        <Col xs={3} sm={3}>
                          <h5 className="text-black">
                            <b>{totalAmount}/-</b>
                          </h5>
                        </Col>
                      </Row>
                    )}
                  </table>
                ) : (
                  <Row>
                    <Col className="text-center">
                      <h2 className="text-danger">Record Not Found.</h2>
                      <Button
                        className="btn-theme"
                        onClick={() => navigate(shop)}
                      >
                        Continue To Shop
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="mt-3 justify-content-center">
                  {getCartlistData?.list?.length &&
                    offersData &&
                    (() => {
                      const firstValidOffer = offersData.find(
                        (offer) => offer.offerType !== 2 && offer.valid
                      );

                      if (!firstValidOffer) return <Offers
                      title={"View all coupons"}
                      orderAmount={totalCartAmountValue}
                      couponRate={coupon}
                      couponTypeStatus={couponStatus}
                      couponAmountStatus={updateCouponAmountStatus}
                    />; // No valid offer found

                      const isCouponApplied =
                        couponStatus &&
                        localStorage.getItem("couponName") ===
                          firstValidOffer.id;

                      return (
                        <Col md={3} sm={12} xs={12}>
                          <div
                            className="p-3 mb-3 rounded border-success"
                            style={{
                              transition: "all 0.3s ease",
                              boxShadow: "rgb(0 0 0 / 26%) 0px 4px 10px",
                            }}
                          >
                            <Row className="align-items-center">
                              <Col md={8} sm={8} xs={8}>
                                <p className="mb-0">
                                  <strong
                                    className="text-black f-13"
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {firstValidOffer.name}
                                  </strong>
                                </p>
                                <p
                                  className="mb-0 text-black"
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {firstValidOffer.description}
                                </p>
                                <Offers
                                  title={"View all coupons"}
                                  orderAmount={totalCartAmountValue}
                                  couponRate={coupon}
                                  couponTypeStatus={couponStatus}
                                  couponAmountStatus={updateCouponAmountStatus}
                                />
                              </Col>
                              <Col md={4} sm={4} xs={4} className="text-end">
                                {!isCouponApplied ? (
                                  <span
                                    onClick={async () => {
                                      const apiResp = await dispatch(
                                        applyCartOffer({
                                          qty: cartItems,
                                          couponName: firstValidOffer.id,
                                        })
                                      );
                                      if (apiResp) {
                                        localStorage.setItem(
                                          "couponName",
                                          apiResp.payload.offer?.id
                                        );
                                        localStorage.setItem("cartoffer",true);
                                        localStorage.setItem(
                                          "couponapplyName",
                                          apiResp.payload.offer?.name
                                        );
                                        localStorage.setItem(
                                          "couponapplyAmount",
                                          apiResp.payload.disc
                                        );
                                        updateCouponAmountStatus(
                                          apiResp.payload.offer?.rate,
                                          true,
                                          apiResp.payload.disc
                                        );
                                        localStorage.removeItem("code");
                                        localStorage.setItem("couponoffer",false);
                                        setDiscount(+apiResp.payload.disc);
                                      }
                                    }}
                                    className="px-3 f-16 py-1 font-weight-bold"
                                    style={{
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                      color: "#ce4a57",
                                      border: "1px solid #ce4a57",
                                    }}
                                  >
                                    Apply
                                  </span>
                                ) : (
                                  <p
                                    onClick={() => {
                                      updateCouponAmountStatus(0, false, 0);
                                      localStorage.removeItem("couponName");
                                      localStorage.removeItem(
                                        "couponapplyAmount"
                                      );
                                    }}
                                    className="px-2 py-1 offercoupon text-danger"
                                  >
                                    <i className="bi bi-trash3-fill"></i>
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })()}
                </Row>
                {/* {(localStorage.getItem("couponName") && discount && totalCartAmountValue && totalCartAmountValue !== "null") ? (
              <p className="f-18 mt-2 mb-0 text-center">
                <strong>
                  Order Summary:{" "}
                  <span>
                    <b>{totalCartAmountValue}/-</b>
                  </span>
                </strong>
              </p>
            ) : null} */}
                {(coupon / 100) * totalCartAmountValue > 1 && (
                  <div className="border py-2 mb-2 rounded">
                    {/* <h6 className="text-success text-center">
                  🎁🎉Congratulations you got <b>{coupon}%</b> Discount.🎁🎉
                </h6> */}
                    <h6 className="text-black text-center">
                      Congratulations you got{" "}
                      <b>{localStorage.getItem("couponapplyName")}</b>.
                    </h6>
                    {/* <h6 className="text-info text-center mb-0">
                  Total Payable Amount:{" "}
                  <span className="text-danger">
                    <b>
                      {totalCartAmountValue} - {discount?.toFixed(0)} ={" "}
                      {(totalCartAmountValue - discount)?.toFixed(0)}
                      /-
                    </b>
                  </span>
                </h6> */}
                  </div>
                )}
              </Col>
            </Row>
            {(coupon / 100) * totalCartAmountValue > 1 && (
              <Row>
                <Col>
                  <h4 className="text-black text-center mb-0">
                    Total Payable Amount
                    <br />
                    <span className="text-success">
                      <b>
                        {totalCartAmountValue} - {discount?.toFixed(0)} ={" "}
                        {(totalCartAmountValue - discount)?.toFixed(0)}
                        /-
                      </b>
                    </span>
                  </h4>
                </Col>
              </Row>
            )}
            {getCartlistData?.list?.length && isAllCustomize ? (
              <Row className="mt-3">
                <Col md={12}>
                  <Button
                    className="theme-btn w-100"
                    onClick={async () => {
                      const apiResp = await dispatch(checkAddressArea());
                      if (apiResp?.payload?.status) {
                        navigate(
                          `${getUserAddress}?amount=${(
                            totalCartAmountValue - discount
                          )?.toFixed(0)}`
                        );
                      }
                    }}
                  >
                    Buy Now <i className="bi bi-arrow-right"></i>
                  </Button>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
