import React, { useState, useEffect, useRef } from "react";
import SideMenu from "../../Components/SideMenu";
import { Rnd } from "react-rnd";
import "./NameSlip.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  customizeProductClone,
  customizeProductImages,
  customizeProductImagesEdit,
  customizeProductsShortImg,
  defaultSlideCustomProductsImg,
  deleteCustomizeProductsImg,
  detailsCustomizeProductsImg,
  getProductLabel,
} from "../../reducers/commonReducer";
import addBtn from "../../images/addbtn.png";
import { fontFamilies, handleContextMenu } from "../../const";

function AddProduct() {
  const width = useRef(window.innerWidth);
  const queryParameters = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const id = queryParameters.get("id");
  const type = queryParameters.get("type");
  //start get product details
  const [imgId, setImgId] = useState("");
  const [loader, setLoader] = useState(false);
  const [rotation, setRotation] = useState("");
  const [productImgName, setProductImgName] = useState("");
  const [dummyText, setDummyText] = useState("");
  const [tabIndex, setTabIndex] = useState();
  const [drageInputUpdate, setDrageInputUpdate] = useState();
  const [inputBoxLeftToRight, setInputBoxLeftToRight] = useState();    
  const [inputBoxTopToBottom, setInputBoxTopToBottom] = useState();    
  const [inputBoxWidth, setInputBoxWidth] = useState();
  const [placeHolderValue, setPlaceHolderValue] = useState();  
  useEffect(() => {
    dispatch(getProductLabel());
    dispatch(
      customizeProductsShortImg({
        productid: id,
      })
    );
  }, []);
  const {
    customizeProductsImgDetails,
    customizeProductsShortImgList,
    defaultSlideSelected,
    imageRotate,
    getProductLabelList,
  } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (customizeProductsImgDetails) {
      setBgImg(customizeProductsImgDetails.productImg || "");
      setBgBannerColor(customizeProductsImgDetails.productbgColor || "");
      setInputs(customizeProductsImgDetails.inputFields || []);
      setImages(customizeProductsImgDetails.customerImg || []);
      setImgId(customizeProductsImgDetails._id);
      setProductImgName(customizeProductsImgDetails?.name);
    }
  }, [customizeProductsImgDetails]);
  //end get product details
  const [imageIndex, setImageIndex] = useState(1);
  const [bgImg, setBgImg] = useState(
    customizeProductsImgDetails?.productImg
      ? customizeProductsImgDetails?.productImg
      : ""
  );
  const [bgBannerColor, setBgBannerColor] = useState(
    customizeProductsImgDetails?.productbgColor
      ? customizeProductsImgDetails?.productbgColor
      : ""
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  // simple text box start
  const [inputs, setInputs] = useState(
    customizeProductsImgDetails?.inputFields
      ? customizeProductsImgDetails?.inputFields
      : []
  );
  const [textColor, setTextColor] = useState(
    customizeProductsImgDetails?.inputFields?.style?.color
      ? customizeProductsImgDetails?.inputFields?.style?.color
      : "#000000"
  );
  const [fontSize, setFontSize] = useState(
    customizeProductsImgDetails?.inputFields?.style?.fontSize
      ? customizeProductsImgDetails?.inputFields?.style?.fontSize
      : "16px"
  );
  const [textLimit, setTextLimit] = useState(
    customizeProductsImgDetails?.inputFields?.maxLength
      ? customizeProductsImgDetails?.inputFields?.maxLength
      : "10"
  );
  const [fontFamily, setFontFamily] = useState(
    customizeProductsImgDetails?.inputFields?.style?.fontFamily
      ? customizeProductsImgDetails?.inputFields?.style?.fontFamily
      : "Arial, sans-serif"
  );
  const [fontWeight, setFontWeight] = useState(
    customizeProductsImgDetails?.inputFields?.style?.fontWeight
      ? customizeProductsImgDetails?.inputFields?.style?.fontWeight
      : "normal"
  );
  const [textAlignment, setTextAlignment] = useState(
    customizeProductsImgDetails?.inputFields?.style?.textAlignment
      ? customizeProductsImgDetails?.inputFields?.style?.textAlignment
      : ""
  );
  const [textType, setTextType] = useState(
    customizeProductsImgDetails?.inputFields?.style?.textType
      ? customizeProductsImgDetails?.inputFields?.style?.textType
      : ""
  );
  const [inputName, setInputName] = useState(
    customizeProductsImgDetails?.inputFields?.name
      ? customizeProductsImgDetails?.inputFields?.name
      : ""
  );
  const [inputLabel, setInputLabel] = useState(
    customizeProductsImgDetails?.inputFields?.label
      ? customizeProductsImgDetails?.inputFields?.label
      : ""
  );
  const [required, setRequired] = useState(
    customizeProductsImgDetails?.inputFields?.required
      ? customizeProductsImgDetails?.inputFields?.required
      : ""
  );
  const addInput = (type) => {
    const newInput = {
      id: inputs.length + 1,
      type,
      value: type === "text" ? "" : "",
      width: 200,
      height: "",
      x: 50,
      y: 50,
      maxLength: textLimit,
      name: inputName,
      required: required,
      label: inputLabel,
      style: {
        color: textColor,
        fontSize: fontSize,
        fontFamily,
        fontWeight,
        textAlignment,
        textType,
        rotation,
      },
    };
    setInputs([...inputs, newInput]);
  };
  const updateInput = (id, updatedProps) => {
    setInputs(
      inputs.map((input, index) =>
        input.id === id
          ? {
              ...input,
              ...updatedProps,
              style: {
                ...input.style,
                rotation: rotation[index] || 0, 
              },
            }
          : input
      )
    );
  };
  const handleTextColorChange = (color) => {
    setTextColor(color);
  };
  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };
  const handleInputNameChange = (e) => {
    setInputName(e.target.value);
  };
  const handleTextLimitChange = (e) => {
    setTextLimit(e.target.value);
  };
  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value);
  };
  const handleFontWeightChange = (e) => {
    setFontWeight(e.target.value);
  };
  const handleTextAlignmentChange = (e) => {
    setTextAlignment(e.target.value);
  };
  const handleTextType = (e) => {
    setTextType(e.target.value)
  }
  const handleRequiredChange = (e) => {
    setRequired(/true/.test(e.target.value));
  };
  const handleRotate = (id, angle) => {
    setRotation((prevRotation) => ({
      ...prevRotation,
      [id]: (prevRotation[id] || 0) + angle,
    }));
  };
  // simple text box end
  //update input field
  const updateDragInput = (inputid) => {
    const [inputEditDetails] = inputs.filter((input) => input?.id === inputid);
    const {
      name,
      required,
      value,
      maxLength: textLimit,
      style: {
        color: textColor,
        fontSize,
        fontFamily,
        fontWeight,
        textAlignment,
        textType,
      },
      width,
      x,
      y,
      label: { id: inputLabel }
    } = inputEditDetails;
    setTextColor(textColor);
    setInputName(name);
    setRequired(required);
    setTextLimit(textLimit);
    setFontSize(fontSize);
    setFontFamily(fontFamily);
    setFontWeight(fontWeight);
    setTextAlignment(textAlignment);
    setTextType(textType);
    setInputLabel(inputLabel);
    setDrageInputUpdate(inputEditDetails?.id)
    setInputBoxLeftToRight(x)
    setInputBoxTopToBottom(y)
    setInputBoxWidth(width)
    setPlaceHolderValue(value)
  };
  const updateDrageInputBox = (id) => {
    const newInput = {
      id,
      type,
      value: type === "text" ? "" : "",
      width: inputBoxWidth,
      value: placeHolderValue,
      x: inputBoxLeftToRight,
      y: inputBoxTopToBottom,
      maxLength: textLimit,
      name: inputName,
      required: required,
      label: inputLabel,
      style: {
        color: textColor,
        fontSize: fontSize,
        fontFamily,
        fontWeight,
        textAlignment,
        textType,
        rotation,
      },
    };
    setInputs((prevInputs) => {
      const inputIndex = prevInputs.findIndex((input) => input.id === id);
      if (inputIndex !== -1) {
        const updatedInputs = [...prevInputs];
        updatedInputs[inputIndex] = { ...newInput };
        return updatedInputs;
      } else {
        return [...prevInputs, newInput];
      }
    });
    setDrageInputUpdate("")
  };
  // simple image box start
  const [images, setImages] = useState(
    customizeProductsImgDetails?.customerImg
      ? customizeProductsImgDetails?.customerImg
      : []
  );

  const handleFileChange = (id, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result;
      setImages([
        ...images,
        {
          id: images.length + 1,
          imageUrl: dataUrl,
          width: 200,
          height: 200,
          x: 50,
          y: 50,
        },
      ]);
    };
    reader.readAsDataURL(file);
  };

  const updateImage = (id, updatedProps) => {
    setImages(
      images.map((image) =>
        image.id === id ? { ...image, ...updatedProps } : image
      )
    );
  };
  // simple image box end
  useEffect(() => {
    const img = new Image();
    img.src = bgImg;
    img.onload = () => {
      setImageDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
  }, [bgImg]);

  useEffect(() => {
    localStorage.setItem("bgImg", bgImg);
    localStorage.setItem("bgBannerColor", bgBannerColor);
    localStorage.setItem("userUploadImages", JSON.stringify(images));
    localStorage.setItem("inputs", JSON.stringify(inputs));
  }, [bgImg, bgBannerColor, inputs, images]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBgImg(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setBgImg(null);
    }
  };
  const handleBackgroundColorChange = (color) => {
    setBgBannerColor(color);
  };

  const deleteInput = (id) => {
    setInputs(inputs.filter((input) => input.id !== id));
  };
  const cloneInput = (input, index) => {
    const newInput = { ...input, id: Date.now(), x: input.x + 10, y: input.y + 10 }; 
    setInputs((prevInputs) => [...prevInputs, newInput]); 
  };
  
  const deleteImg = (id) => {
    setImages(images.filter((input) => input.id !== id));
  };
  const updateCustomizeImg = async () => {
    setLoader(true)
    const apiResp = await dispatch(
      (imgId ? customizeProductImagesEdit : customizeProductImages)({
        inputid: imgId,
        productid: id,
        inputFields: inputs,
        customerImg: images,
        productImg: bgImg,
        productbgColor: bgBannerColor,
        name: productImgName,
      })
    );
    if (apiResp?.payload?.status) {
      dispatch(
        customizeProductsShortImg({
          productid: id,
        })
      );
      dispatch(
        detailsCustomizeProductsImg({
          productid: id,
          index: tabIndex + 1,
        })
      );
      setLoader(false)
    }
  };

//   let newWidth = 600;
//   let newHeight = 0;
//   newHeight = (newWidth / imageDimensions.width) * imageDimensions.height;

//image height width calculate
let newWidth = imageDimensions.width < 600 ? imageDimensions.width : 600;
let newHeight = (newWidth / imageDimensions.width) * imageDimensions.height; 
if (newHeight > 600) {
  newHeight = 600;
  newWidth = (newHeight / imageDimensions.height) * imageDimensions.width; 
}
  return (
    <>
      <SideMenu />
    
      <div className="mt-extra content container-fluid">
        <Row>
          <Col>
            <p className="m-0"
                      style={{
                        textAlign: textAlignment,
                        color: textColor,
                        fontWeight: fontWeight,
                        fontSize: `${fontSize}px`,
                        fontFamily: fontFamily,
                        textTransform: textType
                      }}
                    >
                {dummyText}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={12} xs={12}>
            <Row>
              {!!customizeProductsShortImgList &&
                customizeProductsShortImgList?.map((customImg, index) => (
                  <Col key={index} md={2} className="mt-2">
                    <div
                      className={`mt-2 shadow p-1 text-center ${
                        tabIndex === index ? "bg-success" : ""
                      }`}
                    >
                      {type === "limitedEdition" ? (
                        <Button
                          onClick={() =>
                            dispatch(
                              detailsCustomizeProductsImg({
                                productid: id,
                                index: index + 1,
                              })
                            )
                          }
                          className="theme-btn"
                        >
                          <b>Input</b>
                        </Button>
                      ) : (
                        <>
                          <div className="d-flex gap-10">
                            {index === 0 ? (
                              <p className="bg-danger text-white mb-0 w-80">
                                Main
                              </p>
                            ) : (
                              <p className="bg-warning text-black w-80 mb-0">
                                Printing
                              </p>
                            )}
                            <span className="float-right mt-1">
                              <i
                                onClick={async () => {
                                  const apiResp = await dispatch(
                                    deleteCustomizeProductsImg({
                                      productid: id,
                                      index: index + 1,
                                    })
                                  );
                                  if (apiResp?.payload?.status) {
                                    dispatch(
                                      customizeProductsShortImg({
                                        productid: id,
                                      })
                                    );
                                  }
                                }}
                                className="text-red bi bi-trash3-fill"
                              ></i>
                            </span>
                          </div>
                          <img
                            onClick={() => {
                              dispatch(
                                detailsCustomizeProductsImg({
                                  productid: id,
                                  index: index + 1,
                                })
                              );
                              setTabIndex(index);
                            }}
                            className="text-center"
                            src={customImg?.productImg}
                            alt={customImg?.name}
                            onContextMenu={handleContextMenu}
                          />
                        </>
                      )}
                      <p className="mb-0">
                        <Button
                          disabled={index === 0 ? true : false}
                          onClick={async () => {
                            const apiResp = await dispatch(
                              defaultSlideCustomProductsImg({
                                productid: id,
                                index: index + 1,
                              })
                            );
                            if (apiResp?.payload?.status) {
                              dispatch(
                                customizeProductsShortImg({
                                  productid: id,
                                })
                              );
                            }
                          }}
                          variant={
                            defaultSlideSelected === index + 1
                              ? "success"
                              : "info"
                          }
                          className="px-1 py-0 mt-1 font-12 border"
                        >
                          {index === 0 ? `Main` : `ForPrint`}
                        </Button>
                        <Button
                          onClick={async () => {
                            const apiResp = await dispatch(
                              customizeProductClone({ productid: id, index })
                            );
                            if (apiResp?.payload?.status) {
                              dispatch(
                                customizeProductsShortImg({
                                  productid: id,
                                })
                              );
                            }
                          }}
                          className="mt-1 px-1 py-0 font-12"
                        >
                          Clone
                        </Button>
                        {
                          (defaultSlideSelected === index + 1) && <Button  onClick={async () => {
                            const apiResp = await dispatch(
                              defaultSlideCustomProductsImg({
                                productid: id,
                                index: index + 1,
                                rotate: !imageRotate
                              })
                            );
                            if (apiResp?.payload?.status) {
                              dispatch(
                                customizeProductsShortImg({
                                  productid: id,
                                })
                              );
                            }
                          }}
                          className="px-1 py-0 mt-1 font-12 border" variant={imageRotate ? "success" : "danger"}>
                            Rotate
                          </Button>
                        }
                      </p>
                    </div>
                  </Col>
                ))}
              {!!customizeProductsShortImgList?.length && (
                <Col md={2} className="mt-2">
                  <img
                  onContextMenu={handleContextMenu}
                    className="w-auto"
                    onClick={async () => {
                      const apiResp = await dispatch(
                        customizeProductClone({ productid: id })
                      );
                      if (apiResp?.payload?.status) {
                        dispatch(
                          customizeProductsShortImg({
                            productid: id,
                          })
                        );
                      }
                    }}
                    src={addBtn}
                    alt="Add More"
                  />
                </Col>
              )}
            </Row>
            <div
              style={{
                maxWidth: "600px",
                maxHeight: "600px",
              }}
            >
              <div
                style={{
                  backgroundColor: `${bgBannerColor}`,
                  width: `${imageDimensions.width}px`,
                  height: `${imageDimensions.height}px`,
                  maxWidth: `${newWidth}px`,
                  maxHeight: `${newHeight}px`,
                  backgroundSize: `contain`,
                  backgroundRepeat: `no-repeat`,
                  position: "relative",
                }}
              >
                {images.map((image) => (
                  <Rnd
                    key={image.id}
                    className="image"
                    size={{ width: image.width, height: image.height }}
                    position={{ x: image.x, y: image.y }}
                    onDragStop={(e, d) =>
                      updateImage(image.id, { x: d.x, y: d.y })
                    }
                    onResizeStop={(e, direction, ref, delta, position) => {
                      updateImage(image.id, {
                        width: ref.style.width,
                        height: ref.style.height,
                        ...position,
                      });
                    }}
                    style={{
                      zIndex: imageIndex === 2 ? 1 : 5,
                      position: "absolute",
                      overflow: "hidden",
                    }}
                  >
                    <img
                    onContextMenu={handleContextMenu}
                      src={image.imageUrl}
                      alt="Student"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        opacity: "0.3",
                      }}
                    />
                    <span
                      onClick={() => deleteImg(image.id)}
                      className="bg-danger text-white px-1"
                    >
                      X
                    </span>
                  </Rnd>
                ))}
                <div
                  className="name-slip product-bg-container"
                  style={{
                    backgroundImage: `${
                      bgImg
                        ? `url(${bgImg})`
                        : `url(${customizeProductsImgDetails?.productImg})`
                    }`,
                    width: `${imageDimensions.width}px`,
                    height: `${imageDimensions.height}px`,
                    maxWidth: `${newWidth}px`,
                    maxHeight: `${newHeight}px`,
                    backgroundSize: `contain`,
                    backgroundRepeat: `no-repeat`,
                    position: "relative",
                    zIndex: 3,
                    textAlign: textAlignment,
                    textTransform: textType
                  }}
                >
                  {inputs.map((input, index) => {
                    const translateX =
                      width.current > 500 ? input.x : input.x / 2;
                    const translateY =
                      width.current > 500 ? input.y : input.y / 2;
                    return (
                      <Rnd
                        key={input.id}
                        className="input d-flex"
                        size={{ width: input.width, height: input.height }}
                        position={{ x: input.x, y: input.y }}
                        onDragStop={(e, d) =>
                          updateInput(input.id, { x: d.x, y: d.y }, index)
                        }
                        onResizeStop={(e, direction, ref, delta, position) => {
                          updateInput(
                            input.id,
                            {
                              width: ref.style.width,
                              height: ref.style.height,
                              ...position,
                            },
                            index
                          );
                        }}
                        style={{
                          position: "absolute",
                          zIndex: 4,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            transform:
                            `rotate(${input.style.rotation || 0}deg)` ||
                            `rotate(${rotation[index]}deg)`,
                            display:'flex'
                          }}
                        >
                        <input
                          type="text"
                          value={input.value}
                          onChange={(e) =>
                            updateInput(
                              input.id,
                              {
                                value: e.target.value,
                                translateX,
                                translateY,
                              },
                              index
                            )
                          }
                          className="border"
                          maxLength={textLimit}
                          required={required}
                          name={inputLabel}
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            color: input.style.color || textColor,
                            fontSize:
                              `${input.style.fontSize}px` || `${fontSize}px`,
                            fontFamily: input.style.fontFamily || fontFamily,
                            fontWeight: input.style.fontWeight || fontWeight,
                            textAlign:
                              input.style.textAlignment || textAlignment,
                            textTransform: input.style?.textType || textType
                          }}
                        />
                        <span
                          onClick={() => updateDragInput(input?.id, input?.value)}
                          className="bg-success text-white px-1"
                        >
                          <i className="bi bi-pen-fill"></i>
                        </span>
                        <span
                          onClick={() => deleteInput(input.id)}
                          className="bg-danger text-white px-1"
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </span>
                        <span
                          className="bg-info text-black px-1"
                          onClick={() => handleRotate(index, 2)} 
                        >
                          <i className="bi bi-arrow-clockwise"></i>
                        </span>
                        <span
                          className="bg-warning text-black px-1"
                          onClick={() => handleRotate(index, -2)} 
                        >
                          <i className="bi bi-arrow-counterclockwise"></i>
                        </span>
                        <span
                          className="bg-success text-white px-1"
                          onClick={() => cloneInput(input, index)}
                        >
                          <i className="bi bi-clipboard"></i>
                        </span>
                        </div>
                      </Rnd>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} sm={12} xs={12} className="p-2">
            <Row>
              <Col>
                  <h4 className="text-danger text-center"><b>Min image width 600px.</b></h4>
              </Col>
            </Row>
            <Row>
              {type !== "limitedEdition" && (
                <>
                  <Col md={6}>
                    <Form.Group controlId="formFilebg" className="mb-3">
                      <Form.Label>
                        <b>Background Color</b>
                      </Form.Label>
                      <Form.Control
                        type="color"
                        value={bgBannerColor}
                        onChange={(e) =>
                          handleBackgroundColorChange(e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>
                        <b>Product Image</b>
                      </Form.Label>
                      <Form.Control type="file" onChange={handleImageChange} />
                    </Form.Group>
                  </Col>
                  <hr />
                  <Row className="mb-2">
                    <Col md={6} sm={6} xs={6}>
                      <input
                        type="file"
                        onChange={(e) =>
                          handleFileChange(images.length + 1, e.target.files[0])
                        }
                        style={{ display: "none" }}
                        id="image-input"
                      />
                      <Button
                        variant="info"
                        className="mx-1 mt-4"
                        onClick={() =>
                          document.getElementById("image-input").click()
                        }
                      >
                        Upload Image
                      </Button>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <Form.Group>
                        <Form.Label>Set Image:</Form.Label>
                        <Form.Control
                          as="select"
                          value={imageIndex}
                          onChange={(e) => setImageIndex(+e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value={1}>Edit Image</option>
                          <option value={2}>Save Image</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                </>
              )}
              <Row className="px-0">
                <Col md={12} className="px-0">
                  <label>
                    <b>Dummy Text</b>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Anything....."
                    value={dummyText}
                    max={textLimit}
                    onChange={(e) => setDummyText(e.target.value)}
                    className="mb-1"
                  />
                  <p
                    className="m-0"
                    style={{
                      textAlign: textAlignment,
                      textTransform: textType,
                      color: textColor,
                      fontWeight: fontWeight,
                      fontSize: `${fontSize}px`,
                      fontFamily: fontFamily,
                    }}
                  >
                    {dummyText}
                  </p>
                </Col>
              </Row>
              <Row className="bg-thememain px-2 py-4 text-white">
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Text Color:</Form.Label>
                    <Form.Control
                      type="color"
                      value={textColor}
                      onChange={(e) => handleTextColorChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Input Name:</Form.Label>
                    <Form.Control
                      type="text"
                      value={inputName}
                      onChange={handleInputNameChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Required</Form.Label>
                    <Form.Control
                      as="select"
                      value={required}
                      onChange={handleRequiredChange}
                    >
                      <option value="">Select</option>
                      <option value={true}>Active</option>
                      <option value={false}>InActive</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Text Limit:</Form.Label>
                    <Form.Control
                      type="text"
                      value={textLimit}
                      onChange={handleTextLimitChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Font Size:</Form.Label>
                    <Form.Control
                      type="text"
                      value={parseInt(fontSize, 10)}
                      onChange={handleFontSizeChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>Font Family:</Form.Label>
                    <Form.Control
                      as="select"
                      value={fontFamily}
                      onChange={handleFontFamilyChange}
                    >
                      <option value="">Select FontFamily</option>
                      {fontFamilies?.map((item, index) => (
                        <option key={index} value={item}>{item}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <Form.Group>
                    <Form.Label>Font Weight:</Form.Label>
                    <Form.Control
                      as="select"
                      value={fontWeight}
                      onChange={handleFontWeightChange}
                    >
                      <option value="normal">Normal</option>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <Form.Group>
                    <Form.Label>Text Alignment:</Form.Label>
                    <Form.Control
                      as="select"
                      value={textAlignment}
                      onChange={handleTextAlignmentChange}
                    >
                      <option value="normal">Select</option>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                      <option value="center">Center</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <Form.Group>
                    <Form.Label>Text Type:</Form.Label>
                    <Form.Control
                      as="select"
                      value={textType}
                      onChange={handleTextType}
                    >
                      <option value="">Select</option>
                      <option value="capitalize">Capitalize</option>
                      <option value="lowercase">Lowercase</option>
                      <option value="uppercase">Uppercase</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Input Label: <span className="text-danger"><b>Required in Every Input</b></span></Form.Label>
                    <Form.Control
                      as="select"
                      value={inputLabel}
                      onChange={(e) => setInputLabel(e.target.value)}
                    >
                      <option value="">Select Label</option>
                      {!!getProductLabelList &&
                        getProductLabelList?.map((label, index) => (
                          <option key={index} value={label?.id}>
                            {label?.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} xs={12} className="mt-2">
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={() => {
                      if (inputLabel) {
                        drageInputUpdate ? updateDrageInputBox(drageInputUpdate) : addInput("text");
                      }else(
                        alert("Pls select Input Label.")
                      )
                    }}
                  >
                    {drageInputUpdate ? `Update` : `Add Input Box`}
                  </Button>

                  {/* <Button
                    variant="success"
                    className="w-100"
                    onClick={() => if(inputLabel){
                      drageInputUpdate ? updateDrageInputBox(drageInputUpdate) : addInput("text")
                    }}
                  >
                    {drageInputUpdate ? `Update` : `Add Input Box`}
                  </Button> */}
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Button
              variant="success"
              className="w-100"
              onClick={updateCustomizeImg}
              disabled={!loader ? false : true}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddProduct;
