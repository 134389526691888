import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button, Row, InputGroup, Col, Form } from "react-bootstrap";
import moment from "moment";
import {
  productsList,
  getTaxgsts,
  resetProductList,
  deleteProduct,
  productClone,
  updateProductQrCode,
  deleteQrCode,
} from "../../reducers/commonReducer";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";
import { addProductUrl, customizeProductImgUrl, handleContextMenu, home, openTabWithRefresh, productListUrl } from "../../const";
import ProductImageUpdate from "./ProductImageUpdate";
import NumberPagination from "../../Pagination";

const ProductsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const pageCountner = queryParameters.get("page");
  const [search, setSearch] = useState();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(pageCountner ? pageCountner : 1);

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getCategoriesFrontEnd());
    dispatch(getTaxgsts());
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      // dispatch(resetProductList());
      dispatch(
        productsList({
          search: search,
          categories,
          page,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { productsListData, productsBlank, pageCount } = useSelector(
    (state) => state.commonReducer
  );
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);

  const onPageChange = (number) => {
    setPage(number);
  };

//let filtercategories = categories.length > 0 && categories[0] !== "" ? categories : undefined;

const paginationFilter = {
  categories : (categories.length > 0 && categories[0] !== "") ? [categories] : []
};

// if (filtercategories) {
//   paginationFilter.categories = filtercategories;
// }

// Now `paginationFilter` will only have `categories` if there are valid categories.

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid ">
        <Row className="mb-2">
          <Col md={5}>
            <InputGroup className="search-wrapper">
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="custom-search-icon"
              >
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                className="animated-search-input"
                aria-label="Default"
                placeholder="Search anything..."
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col md={5}>
            <Form.Select
              className="fancy-select-dropdown"
              aria-label="Default select example"
              onChange={(e) => {
                // dispatch(resetProductList());
                dispatch(
                  productsList({
                    categories: e.target.value === "" ? null : [e.target.value],
                  })
                );
                setCategories(e.target.value);
              }}
            >
              <option value="">Select Category</option>
              {!!getCatsFrontEndData &&
                getCatsFrontEndData.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col md={2} className="text-end">
            <Button
              className="animated-clear-btn"
              variant="danger"
              onClick={() => {
                navigate(productListUrl);
                window.location.reload();
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>

        <table className="table mt-4 table-scrolling ">
          <thead className="normalplace">
            <tr className="tables">
              <th className="col-1">Sr</th>
              <th className="col-1">SKU</th>
              <th className="col-1">OrderBy</th>
              <th className="col-1">QR</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Img</th>
              <th className="over-col-size">DefaultImg</th>
              <th className="col-1">CustomizeImg</th>
              <th className="over-col-size">Date</th>
              <th className="over-col-size">MRP</th>
              <th className="over-col-size">Sale</th>
              <th className="over-col-size">Categories</th>
              <th className="over-col-size">ProductType</th>
              <th className="over-col-size">HotSelling</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">ShowOnHome </th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!productsListData &&
              productsListData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      <b>{data?.sku}</b>
                    </td>
                    <td>
                      <b>{data?.orderby}</b>
                    </td>
                    <td>
                    {data?.qr ? <><Button className="p-0" variant="success" onClick={() => {
                      openTabWithRefresh(data?.qr)
                     }}>View QR</Button>
                      <Button
                        variant="danger"
                        className="my-2"
                        onClick={async () => {
                          const deleteConfirm = window.confirm("Are you sure for delete QR.")
                          if(deleteConfirm){
                            const apiResp = await dispatch(
                              deleteQrCode({id: data?.id, qrRemove: true})
                            );
                            if (apiResp?.payload?.status) {
                              dispatch(productsList());
                            }
                          }
                        }}
                      >
                        <i className="bi bi-trash3-fill"></i>
                      </Button></> : <label className="btn btn-warning mt-2 p-0">
                        <b>Upload QR</b>
                        <Form.Control
                          type="file"
                          name="image"
                          className="d-none"
                          onChange={async(event) => {
                            const resumeFile = event.target.files[0];
                            await dispatch(updateProductQrCode({
                              id: data?.id,
                              qr: resumeFile
                            }));
                            await dispatch(productsList());
                          }}
                        />
                      </label>}
                    </td>
                    <td>
                      {data?.name}
                    </td>
                    <td>
                      {data?.productType === "customize" ? (
                        <img
                          onContextMenu={handleContextMenu}
                          src={`${
                            data?.customizeDetails?.[data?.defaultSample]
                              ?.productImg
                          }`}
                          alt={data?.name}
                        />
                      ) : (
                        <img
                          onContextMenu={handleContextMenu}
                          src={`${data?.images?.[data?.defaultImage]}`}
                          alt={data?.name}
                        />
                      )}
                    </td>
                    <td>
                      {data?.productType !== "customize" && (
                        <ProductImageUpdate
                          images={data?.images}
                          productId={data?.id}
                          dimg={data?.defaultImage}
                        />
                      )}
                    </td>
                    <td className="d-flex justify-content-center mt-4">
                      {data?.productType !== "normal" && (
                        <Link
                          to={`${customizeProductImgUrl}?id=${data?.id}&type=${data?.productType}`}
                        >
                          <Button variant="warning">
                            <i className="bi bi-eye-fill"></i>
                          </Button>
                        </Link>
                      )}
                      {data?.status ? <Link
                          to={`/product/${data?.slug}?productStatus=${data?.status}`}
                          target="_blank"
                        >
                          <Button variant="success" className="px-1 py-0 f-13 mx-1">
                            Live Product
                          </Button>
                      </Link> : <Link
                                 to={`/product/${data?.slug}?productStatus=${data?.status}&testing=1`}
                                target="_blank"
                                >
                          <Button variant="danger" className="px-1 py-0 f-13 mx-1">
                          Test Product
                          </Button>
                      </Link>}
                    </td>
                    <td>{moment(data?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>₹ {data?.mrp}</td>
                    <td>₹ {data?.sale}</td>
                    <td>{data?.categories?.name}</td>
                    <td>{data?.productType}</td>
                    <td>
                      <b>
                        {data?.hotSelling === true ? (
                          <span className="text-white bg-success rounded px-2 f-13">
                            Active
                          </span>
                        ) : (
                          <span className="text-white bg-danger rounded px-2 f-13">
                            InActive
                          </span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.status === true ? (
                          <span className="text-white bg-success rounded px-2 f-13">
                            Active
                          </span>
                        ) : (
                          <span className="text-white bg-danger rounded px-2 f-13">
                            InActive
                          </span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.showOnHome === true ? (
                          <span className="text-white bg-success rounded px-2 f-13">
                            Active
                          </span>
                        ) : (
                          <span className="text-white bg-danger rounded px-2 f-13">
                            InActive
                          </span>
                        )}
                      </b>
                    </td>

                    <td>
                      <Link to={`${addProductUrl}?slug=${data?.slug}`}
                      aria-label="Edit">
                        <Button>
                          <i className="bi bi-pen"></i>
                        </Button>
                      </Link>
                      <Button
                        variant="warning"
                        className="my-2"
                        onClick={async () => {
                          const cloneConfirm = window.confirm('Are you sure for clone this product.')
                          if(cloneConfirm){
                            const apiResp = await dispatch(productClone({product : data?.id}));
                            if (apiResp?.payload?.status) {
                              dispatch(productsList());
                            }
                          }
                        }}
                      >
                        <i className="bi bi-clipboard-plus-fill"></i>
                      </Button>
                      <Button
                        variant="danger"
                        className="my-2"
                        onClick={async () => {
                          const deleteConfirm = window.confirm("Are you sure for delete this product.")
                          if(deleteConfirm){
                            const apiResp = await dispatch(
                              deleteProduct(data?.id)
                            );
                            if (apiResp?.payload?.status) {
                              dispatch(productsList());
                            }
                          }
                        }}
                      >
                        <i className="bi bi-trash3-fill"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* {!!productsBlank && productsBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  productsList({
                    search: search,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )} */}
        <Row className="mt-3">
          <Col>
            <NumberPagination
              pageCount={pageCount}
              currentPage={page}
              api={productsList}
              onPageChange={onPageChange}
              filters={paginationFilter}
              pageurl={productListUrl}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductsList;
