import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button, Col, Row } from "react-bootstrap";
import { deleteReview, getReviews, resetProductList, updateReview} from "../../reducers/commonReducer";
import ReviewAction from "./ReviewAction";
import moment from "moment";
import { home, openNewTab, productRating } from "../../const";

const Reviews = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(resetProductList())
    dispatch(getReviews(page));
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
  }, []);

  const { getReviewsData, reviewBlank } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <table className="table table-scrolling ">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr</th>
              <th className="over-col-size">UserName</th>
              <th className="over-col-size">Type</th>
              <th className="over-col-size">Title</th>
              <th className="over-col-size">Description</th>
              <th className="over-col-size">Rating</th>
              <th className="over-col-size">Img/Video</th>
              <th className="over-col-size">Date</th>
              {/* <th className="over-col-size">Recommended</th> */}
              <th className="over-col-size">Status</th>
              <th className="over-col-size">ShowOnHome</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getReviewsData &&
              getReviewsData?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.reviewer_name ? data?.reviewer_name : `${data?.user?.address?.[data?.user?.defaultAddress]?.firstName} ${data?.user?.address?.[data?.user?.defaultAddress]?.lastName}`}</td>
                      <td>{data?.reviewType}</td>
                      <td>{data?.title}</td>
                      <td>{data?.body}</td>
                      <td><ul>
                      {data?.rating > 0 ? productRating(Math.round(data?.rating)) : null}</ul></td>
                      <td>
                        {(data?.reviewType === "content" && data?.picture_urls?.map((img, imgIndex) => <img className="w-50px" onClick={() => openNewTab(img)} key={imgIndex} src={img} alt={data?.title} loading="lazy"/>)) || 
                        (data?.reviewType === "video" && <Button className="theme-btn px-2 py-0" onClick={() => openNewTab(data?.video)}>View Video</Button>)}
                      </td>
                      <td>{moment(data?.review_date).format("DD/MM/YYYY")}</td>
                      {/* <td>{data?.recommended === true ? "True" : "False"}</td> */}
                      <td>{data?.status}</td>
                      <td>{data?.showOnHome ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>}</td>
                      <td>
                      {data?.product && <Link target="_blank" to={`/product/${data?.product?.slug}`} ><Button
                          className="m-1"
                          variant="warning"
                        >
                          <i className="bi bi-eye"></i>
                        </Button></Link>}
                        <ReviewAction
                          title="Update Review"
                          reviewProduct={data?.product?.id}
                          reviewProductName={data?.product?.name}
                          reviewUser={data?.user?.id}
                          reviewTitle={data?.title}
                          reviewRating={data?.rating}
                          reviewDescription={data?.body}
                          reviewRecommended={data?.recommended}
                          reviewStatus={data?.status}
                          id={data?.id}
                          api={updateReview}
                          showOnHome={data?.showOnHome}
                        />
                        <Button
                          className="m-1"
                          variant="danger"
                          onClick={async () => {
                            await dispatch(deleteReview(data?.id));
                            dispatch(getReviews());
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
        {!!reviewBlank && reviewBlank.length > 0 ? (
          <Row>
            <Col>
              <Button
              className="w-100"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getReviews(page + 1)
                  );
                }}
              >
                Load More
              </Button>
            </Col>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default Reviews;
